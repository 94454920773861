<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateMarcoLegalGeneral">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="name" class="form-label">Nombre:</label>
      <input
        v-model="marcoLegalGeneral.name"
        class="form-control"
        id="name"
        required
      />
    </div>
    <div class="mb-3">
      <label for="description" class="form-label">Descripción:</label>
      <input
        v-model="marcoLegalGeneral.description"
        class="form-control"
        id="description"
        required
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, find, update } from "@/services/marcos_legales_generales";
import { showNotification } from "@/services/notification";
export default {
  components: {
    CPrimaryButton,
    C422Errors,
  },
  data() {
    return {
      marcoLegalGeneralId: null,
      errors: {},
      loading: false,
      marcoLegalGeneral: { name: "", description: "" },
    };
  },
  mounted() {
    this.marcoLegalGeneralId = this.$route.params.id;
    this.getMarcoLegalGeneral();
  },
  methods: {
    getMarcoLegalGeneral() {
      if (!this.marcoLegalGeneralId) {
        return;
      }
      find(this.marcoLegalGeneralId).then((response) => {
        this.marcoLegalGeneral = response.data;
      });
    },
    saveOrUpdateMarcoLegalGeneral() {
      this.marcoLegalGeneral.id
        ? this.updateMarcoLegalGeneral()
        : this.saveMarcoLegalGeneral();
    },
    saveMarcoLegalGeneral() {
      create(this.marcoLegalGeneral)
        .then(() => {
          showNotification(201);
          this.$router.push("/admin/marcos-legales-generales");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    updateMarcoLegalGeneral() {
      update(this.marcoLegalGeneralId, this.marcoLegalGeneral).then(() => {
        showNotification(201);
        this.$router.push("/admin/marcos-legales-generales");
      });
    },
  },
};
</script>
