import axios from "@/plugins/Axios";
import { getQueryParams } from "@/shared/methods.vue";

const model = "marcos-legales-generales";

function all({ ...params }) {
  let paramsURL = "";
  for (let param in params) {
    paramsURL += `${param}=${params[param]}`;
  }
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}?${paramsURL}`);
}

function filterWithRelations(queryParams) {
  const relatedParams = {};
  for (const param in queryParams) {
    if (param === "name") {
      relatedParams[param] = queryParams[param];
    } else {
      // add prefix for marcos legales específicos related filters
      relatedParams["versions.marcosLegalesEspecificos." + param] =
        queryParams[param];
    }
  }
  const params = getQueryParams(relatedParams);
  return axios.get(
    `${process.env.VUE_APP_API_URL}/${model}/filtered-by-mles-values?${params}`
  );
}

function create(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, data);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function update(id, data) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, data);
}

function versions(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}/versions`);
}

function createVersions(id, data) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/versions`,
    data
  );
}

function uploadDocument(id, formData) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/documents`,
    formData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
}

export {
  all,
  create,
  find,
  remove,
  update,
  versions,
  createVersions,
  uploadDocument,
  filterWithRelations,
};
