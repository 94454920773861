<template>
  <div v-if="Object.keys(errors).length" class="alert alert-danger">
    <div class="alert-body">
      <ul class="m-0">
        <li v-for="(index, error) in errors" :key="`error${index}`">
          {{ errors[error][0] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "C422Errors",
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
