<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <c-card title="Editar Marco Legal General">
          <marco-legal-general-form />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import MarcoLegalGeneralForm from "@/components/MarcoLegalGeneral/MarcoLegalGeneralForm.vue";
import CCard from "@/components/CCard.vue";
export default {
  components: {
    MarcoLegalGeneralForm,
    CCard,
  },
};
</script>
